<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 卡片视图区域 -->
      <el-row type="flex">
        <el-row>
          <el-col style="display: flex;">
            <el-input v-model="params.orderId" class="search-input" placeholder="订单号" clearable />
            <el-input v-model="params.ttOrderId" v-if="hasTaoteUI" class="search-input" placeholder="淘特订单号" clearable />
            <el-input v-model="params.goodsId" class="search-input" placeholder="商品ID" clearable />
            <el-input v-model="params.goodsTitle" class="search-input" placeholder="商品名称" clearable />
            <el-select v-model="params.status" placeholder="订单状态" class="search-select" >
              <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-select v-model="params.refundStatus" placeholder="售后状态" class="search-select" >
              <el-option v-for="item in refundStatusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-input v-model="params.userId" class="search-input" placeholder="用户ID" clearable />
            <el-input v-model="params.goodsRemark" class="search-input" placeholder="商品描述" clearable />
            <el-select v-model="params.orderType" placeholder="订单类型" class="search-select" >
              <el-option v-for="item in orderTypeList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-input v-model="params.haoshiqiOrderId" class="search-input" placeholder="好食期订单号" clearable />
          </el-col>
         
          <el-col style="display: flex;margin-top: 10px;">
            <el-input v-model="params.userName" class="search-input" placeholder="买家姓名" clearable />
            
            <el-input v-model="params.userPhone" class="search-input" placeholder="买家手机" clearable />
            <el-input v-model="params.logisticsOrderNumber" class="search-input" placeholder="物流单号" clearable />
            <el-select v-if="hasChannelUI" v-model="params.channel" placeholder="渠道" class="search-select" >
              <el-option v-for="item in channelList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-select v-if="hasTaoteUI" v-model="params.ttStatus" placeholder="推送状态" class="search-select" >
              <el-option v-for="item in ttStatusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-date-picker class="search-date" v-model="params.beginDate" type="date" placeholder="开始时间" value-format="yyyy-MM-dd"></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="params.endDate" type="date" placeholder="结束时间" value-format="yyyy-MM-dd"></el-date-picker>
            <el-select style="margin-left: 5px;" v-model="params.miniOrderSign" placeholder="是否确认收货" class="search-select" >
              <el-option v-for="item in orderSignStatusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
          </el-col>
        </el-row>
       
        <el-col v-if="!isGuest" style="display: flex; align-items:flex-end;margin-left: 10px;justify-content:flex-end;">
          <el-button style="height:34px;" type="primary" @click="importExpressNum">导入快递单号</el-button>
          
          <el-button size="mini" type="warning" @click="exportOrder">导出Excel</el-button>
        </el-col>
      </el-row>
      <span style="color: #f00">推送失败: {{ttSendFail.length}}</span>
      <!-- 订单列表数据 -->
      <!-- <el-table :data="orderList" border stripe>
        <el-table-column prop="orderId" label="订单ID"></el-table-column>
        <el-table-column prop="statusName" label="订单状态"></el-table-column> -->
      <el-table :data="orderList"
                style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left"
                     inline
                     class="demo-table-expand">
              <el-form-item label="支付宝交易号：">
                <span>{{ props.row.alipayOrderId}}</span>
              </el-form-item>
              <el-form-item label="是否好食期订单">
                <span v-if="props.row.haoshiqiOrderId != ''">是</span>
                <span v-else>否</span>
              </el-form-item>
              
              <el-form-item label="好食期订单号：">
                <span>{{ props.row.haoshiqiOrderId || '--'}}</span>
              </el-form-item>
             
              <el-form-item label="商品名称：">
                <span>{{ props.row.goodsTitle}}</span>
              </el-form-item>
              <el-form-item label="商品ID：">
                <span>{{ props.row.goodsId}}</span>
              </el-form-item>
              <el-form-item label="支付金额：">
                <span>{{ props.row.needToPay}}</span>
              </el-form-item>
              <el-form-item label="购买数量：">
                <span>{{ props.row.goodsSum}}</span>
              </el-form-item>
              <el-form-item label="用户ID：">
              <secrectText :text="props.row.userId"/>
              </el-form-item>
              <el-form-item label="买家姓名：">
              <secrectText :text="props.row.userName"/>
              </el-form-item>
              <el-form-item label="买家电话：">
              <secrectText :text="props.row.userPhone"/>
              </el-form-item>
              <el-form-item label="买家收货地址：">
              <secrectText :text="props.row.addressName"/>
              </el-form-item>
              <el-form-item label="订单状态：">
                <span>{{ props.row.statusName }}</span>
              </el-form-item>
              <el-form-item label="退款原因：">
                <span>{{ props.row.remark }}</span>
              </el-form-item>
              <el-form-item label="订单创建时间：">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <el-form-item label="物流公司：">
                <span>{{ props.row.logisticsBusiness }}</span>
              </el-form-item>
              <el-form-item label="物流单号：">
                <span>{{ props.row.logisticsOrderNumber }}</span>
              </el-form-item>
              <el-form-item label="售后状态：">
                <span>{{ props.row.refundStatus }}</span>
              </el-form-item>
              <el-form-item label="退货物流公司：">
                <span>{{ props.row.refundLogisticsBusiness}}</span>
              </el-form-item>
              <el-form-item label="退货物流单号：">
                <span>{{ props.row.refundLogisticsNumber}}</span>
              </el-form-item>
              <el-form-item label="退款人姓名：">
                <span>{{ props.row.doRefundTaskBy || '--'}}</span>
              </el-form-item>
              <el-form-item label="退款时间：">
                <span>{{ props.row.doRefundTime || '--'}}</span>
              </el-form-item>
              <el-form-item label="备注：">
                <span>{{ props.row.refundGoodsMessage}}</span>
              </el-form-item>
              <el-form-item label="">
                  <el-button v-if="props.row.status != 0 && props.row.status != 7777 && props.row.status != 3333 && !isGuest" size="mini" @click="_refund(props.row.orderId)" type="danger" >
                  直接退款</el-button>
                  <el-button @click="showUpdateOrder(props.row.orderId, props.row.refundGoodsMessage)" size="mini" type="primary">备注</el-button>
                  <el-button v-if="props.row.statusName == '待发货' && !isGuest && hasErpUI" @click="orderUploadEr(props.row.orderId)" size="mini" type="primary">推送到ERP</el-button>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="订单ID" prop="orderId" />
        <el-table-column v-if="hasTaoteUI" label="淘特订单ID" prop="ttOrderId" />
        <el-table-column label="商品图片">
          <template slot-scope="scope"
                    width>
            <img :src="transferMainPic(scope.row.goodsImg)[0]"
                 style="width: 100px; height: 100px; border: none" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsTitle" />
        <el-table-column label="商品描述" prop="goodsRemark" />
        <el-table-column v-if="hasChannelUI" label="渠道" prop="channelName" />
        <el-table-column label="下单时间" prop="createTime" />
        <el-table-column label="会员价" prop="needToPay" />
        <el-table-column label="实收" prop="truePay" />
        <el-table-column label="订单状态" prop="statusName" >
          <template slot-scope="scope">
            <span>{{ scope.row.statusName}}</span>
            <div v-if="scope.row.mini == 1 && !!scope.row.logisticsOrderNumber && (new Date().getTime()-new Date(scope.row.deliverTime).getTime()) < 24 * 60 * 60 * 1000">
              <span v-if="scope.row.miniOrderSign == 1" style="color: #999;">已确认收货</span>
              <a v-else @click="orderSign(scope.row)" style="color:blue;cursor:pointer;">确认收货</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="hasTaoteUI" label="推送到淘特">
          <template slot-scope="scope">
            <div v-if="scope.row.ttOrderId">
              <div v-if="!scope.row.ttStatus">推送成功</div>
              <div v-else>
                <div>推送失败<br/><span style="font-size: 12px;">{{scope.row.ttStatus}}</span></div>
                <a style="font-size: 12px;color: #409EFF;cursor:pointer;" @click="orderSendToTaoTe(scope.row.id)">重新推送</a>
              </div>
            </div>
            <div v-else-if="scope.row.ttStatus">
                <div>推送失败<br/><span style="font-size: 12px;">{{scope.row.ttStatus == 'ITEM_WITH_AREAS_LIMITED' ? '收货地受限，无法发货' : scope.row.ttStatus}}</span></div>
                <a style="font-size: 12px;color: #409EFF;cursor:pointer;" @click="orderSendToTaoTe(scope.row.id)">重新推送</a>
              </div>
          </template>
        </el-table-column>
        <el-table-column v-if="hasAntUI" label="能量领取">
          <template slot-scope="scope">
            <div v-if="scope.row.adolfAntPowerRecord">
              <div>{{renderAntStatus(scope.row.adolfAntPowerRecord.recordStatus)}}</div>
              <div v-if="scope.row.adolfAntPowerRecord.recordStatus == 2"><a style="font-size: 12px;color: #409EFF;cursor:pointer;" @click="alertStatus(scope.row.adolfAntPowerRecord.errorMessage)">查看原因</a></div>
              <a  v-if="[2, 0].includes(scope.row.adolfAntPowerRecord.recordStatus)" style="font-size: 12px;color: #409EFF;cursor:pointer;" @click="sendEnergy(scope.row.orderId)">手动发送能量</a>
              
              <span v-if="scope.row.adolfAntPowerRecord.recordStatus == 1"  style="font-size: 12px;">{{scope.row.adolfAntPowerRecord.createTime}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="!isGuest">
            <template slot-scope="props">
                <el-button @click="updateLogisticsOpen(props.row.orderId)" type="primary">修改物流</el-button>
                <el-button @click="showUserInfoDialog(props.row)" type="success">修改发货地址</el-button>
                <el-button v-if="props.row.statusName == '待发货'" @click="showSendOrderDialog(props.row)" type="primary">发货</el-button>
            </template>
          </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="params.currentPage"
                     :page-sizes="[3, 5, 10, 15]"
                     :page-size="params.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <a id="exportUrl"></a>
    <!-- 编辑商品对话框 -->
    <el-dialog title="提示"
               :visible.sync="importExpressNumDialogVisible"
               width="50%"
               @close="importExpressNumDialogClosed">
      <!-- 主要内容 -->
      <el-form :model="importExpressNumForm"
               ref="importExpressNumFormRef"
               label-width="100px">
        <el-upload class="upload-demo"
                   action="/api/OrderAdminModule/adolf-order/OrderInExcel"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :before-remove="beforeRemove"
                   multiple
                   :limit="3"
                   :on-exceed="handleExceed"
                   :on-success="onImportSuccess"
                   :file-list="fileList">
          <el-button size="small"
                     type="primary">点击上传</el-button>
          <div slot="tip"
               class="el-upload__tip">只能上传excel文件，且不超过500kb</div>
        </el-upload>
      </el-form>
      <!-- 底部 -->
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="importExpressNumDialogClosed">确 定</el-button>
        <!-- <el-button @click="importExpressNumDialogVisible = false">取 消</el-button> -->
      </span>
    </el-dialog>
    <!-- 编辑商品对话框 -->
    <el-dialog title="导入结果" :visible.sync="resultDialog" width="50%"
               @close="hideResultDialog">
      <!-- 主要内容 -->
      <div>
          <pre style="white-space: pre-wrap;max-height: 300px;overflow: auto;">{{importResult}}</pre>
      </div>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hideResultDialog">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改物流对话框 -->
    <el-dialog title="修改物流" :visible.sync="updateLogisticsVisible" width="50%"
               @close="updateLogisticsClosed">
      <div style="margin-bottom: 20px;">订单号：{{updateLogisticsOrderId}}</div>
      <!-- 主要内容 -->
      <el-form :model="updateLogisticsForm"
               ref="updateLogisticsFormRef"
               label-width="100px">

          <el-input v-model="updateLogisticsForm.logisticsBusiness" style="margin-bottom: 20px;" placeholder="物流公司" clearable />
          <el-input v-model="updateLogisticsForm.logisticsOrderNumber" placeholder="物流单号" clearable />
      </el-form>
      <!-- 底部 -->
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary" @click="updateLogistics">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改发货地址对话框 -->
    <el-dialog title="修改发货地址" :visible.sync="updateUserInfoVisible" width="50%"
               @close="updateUserInfoClosed">
      <div style="margin-bottom: 20px;">订单号：{{updateUserInfoOrderId}}</div>
      <!-- 主要内容 -->
      <el-form label-width="100px">

          <el-input v-model="updateUserInfoForm.userName" style="margin-bottom: 20px;" placeholder="买家姓名" clearable />
          <el-input v-model="updateUserInfoForm.userPhone" style="margin-bottom: 20px;" placeholder="买家电话" clearable />
          <el-input v-model="updateUserInfoForm.addressName" placeholder="买家地址" clearable />
      </el-form>
      <!-- 底部 -->
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary" @click="updateUserInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发货对话框 -->
    <el-dialog title="发货" :visible.sync="sendOrderVisible" width="50%"
               @close="sendOrderClosed">
      <div style="margin-bottom: 20px;">订单号：{{sendOrderId}}</div>
      <!-- 主要内容 -->
      <el-form label-width="100px">

          <el-row >
            <el-select v-model="businessIndex" style="width: 100%">
              <el-option v-for="(item,idx) in businessList" :key="idx" :label="`${item.name}`" :value="idx"></el-option>
            </el-select>
          </el-row>
          <el-input style="margin-top: 15px;" v-model="logisticsNumber" placeholder="物流单号" clearable />
      </el-form>
      <!-- 底部 -->
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary" @click="sendOrder">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="updateOrderVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-row style="margin-top: 10px;">
          <el-input type="textarea" v-model="refundGoodsMessage" placeholder="请输入备注信息" clearable />
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdateOrderSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
  import qs from 'qs'
  import secrectText from '../secrectText.vue'

export default {
  //                   action="https://adf.lvzhangkeji.com/api/OrderAdminModule/adolf-order/OrderInExcel"
  components: {
    secrectText
  },
  data () {
    return {
      isGuest: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        ttOrderId: '',
        orderId: '',
        goodsId: '',
        goodsTitle: '',
        userName: '',
        userPhone: '',
        logisticsOrderNumber: '',
        status: '',
        refundStatus: '',
        beginDate: '',
        endDate: '',
        userId: '',
        goodsRemark: '',
        channel: '',
        ttStatus: '',
        miniOrderSign: '',
        haoshiqiOrderId:'',
      },
      statusList: [
        {key: '全部', value: null},
        {key: '待付款', value: '0'},
        {key: '待发货', value: '9000'},
        {key: '已发货', value: '8888'},
        {key: '已完成', value: '9999'},
        {key: '已取消', value: '7777'},
        {key: '已退款', value: '3333'},
        {key: '等待商家收货', value: '1111'},
        {key: '商家收到退货', value: '2222'},
      ],
      refundStatusList: [
        {key: '全部', value: null},
        {key: '售后中', value: '售后中'},
        {key: '非售后', value: '非售后'},
      ],
      ttStatusList: [
        {key: '全部', value: null},
        {key: '成功', value: '1'},
        {key: '失败', value: '2'},
      ],

      orderSignStatusList: [
        {key: '全部', value: ''},
        {key: '交易组件订单', value: '2'},
        {key: '是', value: '1'},
        {key: '否', value: '0'},
      ],
      orderTypeList: [
        {key: '全部', value: ''},
        {key: '好食期订单', value: '1'},
        {key: '系统订单', value: '0'},
      ],
      // 总条数
      total: 0,
      importResult: '',
      // 订单列表
      orderList: [],
      //上传弹窗显示隐藏
      importExpressNumDialogVisible: false,
      updateLogisticsVisible: false,
      updateUserInfoVisible: false,
      resultDialog: false,
      // 上传文件列表
      fileList: [
        // { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
      importExpressNumForm: {},
      updateLogisticsForm: {},
      updateUserInfoForm: {
        userName: '',
        userPhone: '',
        addressName: '',
      },
      updateLogisticsOrderId:null,
      updateUserInfoOrderId:null,
      sendOrderId:null,
      currentId: '',
      refundGoodsMessage: '',
      updateOrderVisible: false,
      sendOrderVisible: false,
      channelList: [],
      hasChannelUI: false,
      hasErpUI: false,
      hasAntUI: false,
      hasTaoteUI: false,
      businessIndex: '',
      logisticsNumber: '',
      businessList: [],
      ttSendFail: []
    }
  },
  created () {
    // 订单列表
    this.GetOrders()
    this.isGuest = isGuest();
    this.hasErpUI = window.hasErpUI();
    this.hasChannelUI = window.hasChannelUI();
    this.hasAntUI = window.hasAntUI();
    this.hasTaoteUI = window.hasTaoteUI();
    this.$api.GetGoodsChannelList({currentPage: 1, pageSize: 50}).then((res) => {
      if (res.code !== 200) return;
      this.channelList = [].concat([{id: null, name: '全部'}], res.data.records)
    })
    this.$api.QueryBusinessList({}).then((res) => {
        this.businessList = res.data
        this.businessIndex = 0;
    })
  },
  methods: {
    alertStatus(s){
      alert(s)
    },
    // 订单列表
    GetOrders () {
      this.$api.GetOrders(this.params).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求订单列表失败')
        }
        //  else {
        //   this.$message.success('请求订单列表成功')
        // }
        console.log(res)
        if(this.hasTaoteUI){
          this.orderList = res.data.orderList.records
          this.total = res.data.orderList.total
          this.ttSendFail = res.data.sentFail
        }else{
          this.orderList = res.data.records
          this.total = res.data.total
        }
      })
    },
    search(){
      let {beginDate, endDate} = this.params;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      this.params.currentPage = 1;
      this.GetOrders();
    },
    deleteOrder(id){
      this.$confirm('确定删除订单吗？', '订单操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            console.log('按下 确定')
            this.$api.BatchDeleteOrdersById([id.toString()]).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.GetOrders();
            })
            
          }
          else {
            console.log('按下 取消')
          }
        }
      })
    },
    orderUploadEr(orderId){
      this.$confirm('确定推送订单到ERP吗？', '订单操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.orderUploadEr({orderId}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.GetOrders();
            })
            
          }
          
        }
      })
    },
    showUpdateOrder(orderId, msg){
      this.currentId = orderId;
      this.refundGoodsMessage = msg;
      this.updateOrderVisible = true;
    },

    onUpdateOrderSubmit(){
      this.$api.AddRemark({
        orderId: this.currentId, 
        refundGoodsMessage: this.refundGoodsMessage,
      }).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('操作失败:' + (res.message || ''))
          }
          this.$message.success('修改成功')
          this.GetOrders();
          this.updateOrderVisible = false;
      })
    },
    // 选中页面值
    handleSizeChange (newSize) {
      console.log(newSize)
      this.params.pageSize = newSize
      this.GetOrders()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      console.log(NewPage)
      this.params.currentPage = NewPage
      this.GetOrders()
    },
    exportOrder(){
      let p = {...this.params};
      delete p.currentPage;
      delete p.pageSize;
      Object.keys(p).map(o => {
        if(!p[o]){
          delete p[o]
        }
      })
      let {beginDate, endDate} = p;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      window.open('/api/OrderAdminModule/adolf-order/OrderTestExcelBy'+ "?" + qs.stringify(p))
      return;
    },
    //导入订单弹窗
    importExpressNum () {
      this.importExpressNumDialogVisible = !this.importExpressNumDialogVisible
    },
    // 监听分类编辑对话框的关闭事件
    importExpressNumDialogClosed () {
      this.importExpressNumDialogVisible = false
    },
    //
    updateLogisticsOpen (orderId) {
      this.updateLogisticsOrderId = orderId;
      this.updateLogisticsVisible = !this.updateLogisticsVisible
    },
    // 
    updateLogisticsClosed () {
      this.updateLogisticsForm = {}
      this.updateLogisticsVisible = false
    },
    //
    showUserInfoDialog (order) {
      this.updateUserInfoOrderId = order.id;
      this.updateUserInfoForm.userName = order.userName;
      this.updateUserInfoForm.userPhone = order.userPhone;
      this.updateUserInfoForm.addressName = order.addressName;
      this.updateUserInfoVisible = !this.updateUserInfoVisible
    },
    showSendOrderDialog (order) {
      this.sendOrderId = order.orderId;
      this.sendOrderVisible = !this.sendOrderVisible
    },
    // 
    updateUserInfoClosed () {
      this.updateUserInfoForm = {
        userName: '',
        userPhone: '',
        addressName: '',
      }
      this.updateUserInfoVisible = false
    },
    sendOrderClosed () {
      this.senOrderForm = {}
      this.senOrderVisible = false
    },
    hideResultDialog(){
      this.resultDialog = false
    },
    //上传组件
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //直接退款
    _refund(orderId){
      this.$confirm('确定直接退款吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        callback: action => {
          if (action === 'confirm') {
            this.$api.refundOrder(3, {orderId}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('操作成功')
              this.GetOrders();
            })
          }
        }
      })
    },
    onImportSuccess(e){
      
      this.importExpressNumDialogVisible = false;
      this.resultDialog = true;
      var resText = JSON.stringify(e, null, 2);
      resText = resText.replace('orderSum', '订单总数').replace('successSum', '成功').replace('errorSum', '失败').replace('errorOrders', '失败单号')
      this.importResult = resText;
    },
    updateLogistics(){
      let params = {
        orderId: this.updateLogisticsOrderId,
        ...this.updateLogisticsForm
      }
      this.$api.UpdateLogisticsOrderNumber(params).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('操作失败:' + (res.message || ''))
        }
        this.$message.success('操作成功')
        this.GetOrders();
        this.updateLogisticsForm = {}
        this.updateLogisticsVisible = false
      })
    },
    updateUserInfo(){
      let params = {
        id: this.updateUserInfoOrderId,
        ...this.updateUserInfoForm
      }
      this.$api.UpdateOrderUserMessage(params).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('操作失败:' + (res.message || ''))
        }
        this.$message.success('操作成功')
        this.GetOrders();
        this.updateUserInfoForm = {
          userName: '',
          userPhone: '',
          addressName: '',
        }
        this.updateUserInfoVisible = false
      })
    },
    sendOrder(){
      let params = {
        orderId: this.sendOrderId,
        logisticsOrderNumber: this.logisticsNumber,
        logisticsBusiness: this.businessList[this.businessIndex].name
      }
      this.$api.orderSend(params).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('操作失败:' + (res.message || ''))
        }
        this.$message.success('操作成功')
        this.GetOrders();
        this.businessIndex = 0
        this.logisticsNumber = ''
        this.sendOrderVisible = false
      })
    },
    sendEnergy(orderId){
      this.$confirm('确认发送能量？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        callback: action => {
          if (action === 'confirm') {
            this.$api.sendEnergy({orderId}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('发放失败:' + (res.message || ''))
              }
              this.$message.success('发放成功')
              this.GetOrders();
            })
          }
        }
      })
    },
    renderAntStatus(status){
      if(status == 0) return '未发放';
      if(status == 1) return '已发放';
      if(status == 2) return '发放失败';
      return ''
    },
    orderSendToTaoTe(id){
      this.$confirm('确认重新发送？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        callback: action => {
          if (action === 'confirm') {
            this.$api.orderSendToTaoTe({id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('发放失败:' + (res.message || ''))
              }
              this.$message.success('发放成功')
              this.GetOrders();
            })
          }
        }
      })
    },
    orderSign(row){
      this.$api.orderSign({id: row.id}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('确认失败:' + (res.message || ''))
        }
        this.$message.success('确认成功')
        this.GetOrders();
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
